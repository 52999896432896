* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ddd;
}

.ant-btn-primary {
    background: #4096ff;
    outline: none;
}

.firebase-emulator-warning {
    display: none;
}
.search-wrapper {
    width: 100%; /* Chiều rộng của phần tử cha */
}

.search-wrapper > div {
    width: 100%; /* Kế thừa chiều rộng từ phần tử cha */
}

.pseudo-action::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 30px;
    background: transparent;
    top: -30px;
    right: 0;
}
